import { createAction, props } from '@ngrx/store';

export const updateStateParams = createAction('[app] Update State Params', props<{ stateParams: any }>());

export const updateStateCardPrices = createAction('[app] Update State Card Prices', props<{ stateCardPrices: any }>());

export const updateStateCarduuid = createAction('[app] Update State Carduuid', props<{ stateCarduuid: any }>());

export const updateStateLoadcard = createAction('[app] Update State Loadcard', props<{ stateLoadcard: any }>());

export const updateStateCardId = createAction('[app] Update State Card Id', props<{ stateCardId: any }>());

export const updateStateButton = createAction('[app] Update State Button', props<{ stateButton: any }>());

export const updateStateCheckCost = createAction('[app] Update State Check Cost', props<{ stateCheckCost: any }>());

export const updateStateFetchCard = createAction('[app] Update State Fetch Card', props<{ stateFetchCard: any }>());

export const updateUiSelect = createAction('[app] Update Ui Select', props<{
  param: {
    value?: any,
  }}>());

export const fetchCards = createAction('[App] Fetch Cards', props<{ param: any }>());

export const saveButtonSelection = createAction('[App] Save Button Selection', props<{ param: any }>());

export const buyCard = createAction('[App] Buy Card', props<{ param: any }>());

export const fetchAvailableCardPrices = createAction('[App] Fetch Available Card Prices', props<{ param: any }>());

export const getCard = createAction('[App] Get Card', props<{ param: any }>());

export const loadCard = createAction('[App] Load Card', props<{ param: any }>());

export const checkCost = createAction('[App] Check Cost', props<{ param: any }>());

export const wait = createAction('[App] Wait', props<{ param: any }>());
