<nb-layout withScroll [class.header-hidden]="!(layout$ | async)?.header">

  <nb-layout-header *ngIf="(layout$ | async)?.header" class="PaymentHeader payment-header">
    <div class="space Space1 space1" >
      <p class="Text3 text3 text-alignment-left text-transform-none paragraph" ngClass="text-basic"></p>
      <h5 class="AppHeading app-heading text-alignment-left text-transform-none" ngClass="text-basic">Crypto Cards</h5>
    </div>
  </nb-layout-header>

  <nb-sidebar *ngIf="(layout$ | async)?.sidebar" class="Sidebar sidebar" responsive="true" [collapsedBreakpoints]="['xs', 'is', 'sm']" [compactedBreakpoints]="[]">
    <div class="space SidebarSpace sidebar-space" >
      <nb-menu class="Menu menu" [items]="items" ngClass="menu-text-basic"></nb-menu>
    </div>
  </nb-sidebar>

  <nb-layout-column [style.padding]="padding$ | async">
    <router-outlet></router-outlet>
  </nb-layout-column>

</nb-layout>
