import { createReducer, on } from '@ngrx/store';

import * as AppActions from './app.actions';

export interface State {
  uiSelect: {
      value: any;
  };
  stateFetchCard: any;
  stateCheckCost: any;
  stateButton: any;
  stateCardId: any;
  stateLoadcard: any;
  stateCarduuid: any;
  stateCardPrices: any;
  stateParams: any;
}

const initialState: State = {
  uiSelect: {
      value: '',
  },
  stateFetchCard: {
  	data: {
  		cryptocards_cards: [
  			{
  				card_number: '',
  				card_image: '',
  				card_type: ''
  			}
  		]
  	}
  },
    stateCheckCost: {"data":{"update_cryptocards_cards":{"returning" : []}, "cryptocards_cards":[{"confirmations" : 0}]}},
    stateButton: {},
    stateCardId: 0,
    stateLoadcard: {},
    stateCarduuid: '',
    stateCardPrices: {"data":{"cryptocards_cards":[]}},
    stateParams: {"data":{"cryptocards_cards":[{"card_number" : "", "card_image" : "", "card_type" : ""}]}},
};

export const reducer = createReducer(
  initialState,
  on(AppActions.updateUiSelect, (state: State, changes) => ({ ...state, uiSelect: { ...state.uiSelect, ...changes.param }})),
  on(AppActions.updateStateFetchCard, (state: State, { stateFetchCard }) => ({ ...state, stateFetchCard })),
  on(AppActions.updateStateCheckCost, (state: State, { stateCheckCost }) => ({ ...state, stateCheckCost })),
  on(AppActions.updateStateButton, (state: State, { stateButton }) => ({ ...state, stateButton })),
  on(AppActions.updateStateCardId, (state: State, { stateCardId }) => ({ ...state, stateCardId })),
  on(AppActions.updateStateLoadcard, (state: State, { stateLoadcard }) => ({ ...state, stateLoadcard })),
  on(AppActions.updateStateCarduuid, (state: State, { stateCarduuid }) => ({ ...state, stateCarduuid })),
  on(AppActions.updateStateCardPrices, (state: State, { stateCardPrices }) => ({ ...state, stateCardPrices })),
  on(AppActions.updateStateParams, (state: State, { stateParams }) => ({ ...state, stateParams })),
);
