import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, asyncScheduler, combineLatest, iif } from 'rxjs';
import * as AppActions from './app.actions';
import { switchMap, withLatestFrom, map, observeOn, mapTo, tap, catchError, mergeMap, share, take } from 'rxjs/operators';
import * as fromApp from './app.reducer';
import * as appSelectors from './app.selectors';
import { select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class AppEffects {

  private fetchCards$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.fetchCards.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null })
      .pipe(
        switchMap(({ data, error }) => this.http.post(`https://ccapi.online/v1/graphql`, `{\"query\":\"{cryptocards_cards(where: {uuid: {_is_null: true}}, distinct_on: card_type) {\\n      card_type,\\n  card_id\\n, card_image\\n}}\\n\",\"variables\":{}}`, {
          headers: {
            'Content-Type': `application/json`,
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateParams({ stateParams: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private saveButtonSelection$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.saveButtonSelection.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null })
      .pipe(
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateButton({ stateButton: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private buyCard$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.buyCard.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null })
      .pipe(
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateParams({ stateParams: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        map(({ data, error }) => {
          
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
              var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
              return v.toString(16);
            });
        }),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateCarduuid({ stateCarduuid: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => combineLatest([of({ data, error }), 
          this.store.pipe(select(appSelectors.getStateParams)),
          this.store.pipe(select(appSelectors.getStateCarduuid))
        ]).pipe(take(1))),
        tap(([{ data, error }, stateParams, stateCarduuid]: any) => {
        const urlTree = this.router.createUrlTree([`purchase`], {
          queryParams: {
            'cardType': stateParams.cardType,
            'uuid': stateCarduuid,
            'usdCost': stateParams.usdCost,
          }
        });
        this.router.navigateByUrl(urlTree);
      }),
      )
    ),
    share(),
  ), { dispatch: false });

  private fetchAvailableCardPrices$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.fetchAvailableCardPrices.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null })
      .pipe(
        switchMap(({ data, error }) => this.http.post(`https://ccapi.online/v1/graphql`, `{\"query\":\"query MyQuery($cardType: String) {\\n  cryptocards_cards(where: {card_type: {_eq: $cardType}, _and: {uuid: {_is_null: true}}}, distinct_on: usd_cost) {\\n    value: usd_cost\\n    label: usd_cost\\n  }\\n}\",\"variables\":{\"cardType\":\"${data.cardType}\"}}`)),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateCardPrices({ stateCardPrices: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private getCard$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.getCard.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null })
      .pipe(
        withLatestFrom(this.store.pipe(select(appSelectors.getActiveRoute))),
        switchMap(([{ data, error }, activeRoute]) => this.http.post(`https://ccapi.online/v1/graphql`, `{\"query\":\"query MyQuery {\\n  cryptocards_cards(where: {uuid: {_eq: \\\"${activeRoute.queryParams.uuid}\\\"}}) {\\n    card_type\\n    card_number\\n    card_image\\n  }\\n}\",\"operationName\":\"MyQuery\"}`, {
          headers: {
            'Content-Type': `application/json`,
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateFetchCard({ stateFetchCard: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private loadCard$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.loadCard.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null })
      .pipe(
        withLatestFrom(this.store.pipe(select(appSelectors.getActiveRoute))),
        mergeMap(([{ data, error }, activeRoute]) => {
          const emitter = of({ data, error });
          return iif(
            () => {
              return activeRoute.queryParams.cardType !== null;
            },
            emitter.pipe(
              withLatestFrom(this.store.pipe(select(appSelectors.getActiveRoute))),
              switchMap(([{ data, error }, activeRoute]) => this.http.post(`https://ccapi.online/v1/graphql`, `{
            "query": "mutation MyMutation {\\r\\n  update_cryptocards_cards(where: {card_type: {_eq: \\\"${activeRoute.queryParams.cardType}\\\"}, usd_cost: {_eq: \\\"${activeRoute.queryParams.usdCost}\\\"}, uuid: {_is_null: true}}, _set: {uuid: \\\"${activeRoute.queryParams.uuid}\\\"}) {\\r\\n    returning {\\r\\n      payment_address\\r\\n    }\\r\\n  }\\r\\n}"
          }`, {
                headers: {
                  'Content-Type': `application/json`,
                },
              })),
              map(data => ({ data, error: null })),
              catchError(error => of({ data: null, error })),
              switchMap((data) => of(data).pipe(
                map(({ data, error }) => {  
                  return data
                }),
                tap((value) => this.store.dispatch(AppActions.updateStateLoadcard({ stateLoadcard: value }))),
                mapTo(data),
              )),
              observeOn(asyncScheduler),
              catchError(error => of({ data: null, error })),
              switchMap(({ data, error }) => {
                this.store.dispatch(AppActions.checkCost({ param: data }));
                return this.checkCost$
                  .pipe(
                    map((result) => result && result.data ? result : { data: result, error: null }),
                    catchError(error => of({ data: null, error })),
                    take(1),
                  );
              }),
            ),emitter,
          )}),
      )
    ),
    share(),
  ), { dispatch: false });

  private checkCost$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.checkCost.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null })
      .pipe(
        withLatestFrom(this.store.pipe(select(appSelectors.getStateCheckCost))),
        mergeMap(([{ data, error }, stateCheckCost]) => {
          const emitter = of({ data, error });
          return iif(
            () => {
              if(stateCheckCost.data === undefined) return true
              return stateCheckCost.data.cryptocards_cards[0].confirmations != 6
              
            },
            emitter.pipe(
              withLatestFrom(this.store.pipe(select(appSelectors.getActiveRoute))),
              switchMap(([{ data, error }, activeRoute]) => this.http.post(`https://ccapi.online/v1/graphql`, `{\"query\":\"query MyQuery {\\n  cryptocards_cards(where: {uuid: {_eq: \\\"${activeRoute.queryParams.uuid}\\\"}}) {\\n    crypto_cost\\n    card_number\\n payment_address\\n confirmations\\n  }\\n}\",\"variables\":{}}`)),
              map(data => ({ data, error: null })),
              catchError(error => of({ data: null, error })),
              switchMap((data) => of(data).pipe(
                map(({ data, error }) => {  
                  return data
                }),
                tap((value) => this.store.dispatch(AppActions.updateStateCheckCost({ stateCheckCost: value }))),
                mapTo(data),
              )),
              observeOn(asyncScheduler),
              catchError(error => of({ data: null, error })),
              switchMap(({ data, error }) => {
                this.store.dispatch(AppActions.wait({ param: data }));
                return this.wait$
                  .pipe(
                    map((result) => result && result.data ? result : { data: result, error: null }),
                    catchError(error => of({ data: null, error })),
                    take(1),
                  );
              }),
            ),
            emitter.pipe(
              withLatestFrom(this.store.pipe(select(appSelectors.getStateCarduuid))),
              tap(([{ data, error }, stateCarduuid]) => {
              const urlTree = this.router.createUrlTree([`card`], {
                queryParams: {
                  'uuid': stateCarduuid,
                }
              });
              this.router.navigateByUrl(urlTree);
            }),
            ),
          )}),
      )
    ),
    share(),
  ), { dispatch: false });

  private wait$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.wait.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null })
      .pipe(
        
        switchMap(async ({ data, error }) => {
          await new Promise(r => setTimeout(r, 2000));
        }),
        switchMap((value: any) => value?.subscribe ? value : of(value)),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => {
          this.store.dispatch(AppActions.checkCost({ param: data }));
          return this.checkCost$
            .pipe(
              map((result) => result && result.data ? result : { data: result, error: null }),
              catchError(error => of({ data: null, error })),
              take(1),
            );
        }),
      )
    ),
    share(),
  ), { dispatch: false });

  constructor(private router: Router,
    private store: Store<fromApp.State>,
    private http: HttpClient,
    private actions$: Actions) {
  }

}
