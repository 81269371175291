import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeColorPipe } from './pipes/theme-color.pipe';
import { NbSpinnerModule, NbCardModule, NbSelectModule, NbButtonModule, NbIconModule, NbProgressBarModule } from '@nebular/theme';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { UiValueUpdateDirective } from './directives/ui-value-update.pipe';
import { ToDatePipe } from './pipes/to-date.pipe';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [ThemeColorPipe, SanitizeHtmlPipe, UiValueUpdateDirective, ToDatePipe],
  imports: [
    CommonModule,
    NbSpinnerModule,
    NbCardModule,
    NbSelectModule,
    FormsModule,
    NbButtonModule,
    NbIconModule,
    NbProgressBarModule
  ],
  exports: [ThemeColorPipe, NbSpinnerModule, SanitizeHtmlPipe, NbCardModule, UiValueUpdateDirective, ToDatePipe, NbSelectModule, FormsModule, NbButtonModule, NbIconModule, NbProgressBarModule]
})
export class SharedModule { }
